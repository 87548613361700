<template>
  <div>
    <skeleton v-if="loading" />
    <div v-else>
      <quote-status-bar
        :item="quoteStatusItems"
        :quote-status-bar="quoteStatusBarItems"
        class="ml-1"
      />
      <order-exception-status :item="getServiceInformation" />

      <b-row class="mt-1">
        <b-col cols="6">
          <service-order-dispatch
            :order-item="getServiceInformation"
          />
        </b-col>
        <b-col cols="6">
          <service-order-return
            class=""
            :order-item="getServiceInformation"
          />
        </b-col>
      </b-row>
      <service-order-information
        class="mt-1"
        :order-item="getServiceInformation"
      />
      <service-order-items class="mt-1" />
      <service-order-form-bottom />
    </div>

  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import ServiceOrderInformation
from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderInformation.vue'
import ServiceOrderFormBottom
from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderFormBottom.vue'
import ServiceOrderItems from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderItems.vue'
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import skeleton from '@/views/main/warehouse/view/pick/service-order/components/Skeleton.vue'
import OrderExceptionStatus from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import { BCol, BRow } from 'bootstrap-vue'
import warehouseConfig from '@/views/main/warehouse/config'

export default {
  name: 'ServiceOrderForm',
  components: {
    BCol,
    BRow,
    skeleton,
    ServiceOrderDispatch,
    ServiceOrderReturn,
    QuoteStatusBar,
    ServiceOrderInformation,
    ServiceOrderFormBottom,
    ServiceOrderItems,
    OrderExceptionStatus,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
    }
  },
  computed: {
    getServiceInformation() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
    quoteStatusItems() {
      return this.getServiceInformation ? {
        state: this.getServiceInformation.fulfillment_state,
        status: this.getServiceInformation.fulfillment_status,
      } : {}
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.getServiceInformation.states)
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/getServiceOrder`, { id: this.id }).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_INFORMATION`, data)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
      this.loading = false
    })
  },
  setup() {
    const { transferFields, MODULE_NAME } = config()
    const { quoteStatusItemsMerge } = warehouseConfig()

    return {
      transferFields,
      MODULE_NAME,
      quoteStatusItemsMerge,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
